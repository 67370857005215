<template>
  <div class='border-t bg-white flex flex-col py-12'>
    <div class='layout-container w-full bg-white flex flex-col-reverse lg:flex-row-reverse lg:gap-x-8 gap-y-8 justify-between px-4 lg:px-0 text-xs lg:text-sm text-gray-700'>
      <div class='flex flex-col justify-between items-center lg:items-end'>
        <div>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/koa-logo.jpeg'
             class='h-12 cursor-pointer mb-6 w-auto'
             @click='goToHome'/>
        </div>
        <div
          v-if='hasValidToken' 
          class='text-left uppercase border px-4 py-2 text-center rounded-md hover:shadow-md cursor-pointer'
          @click='logoutApp'>
          logout
        </div>
      </div>
      <div class='text-sm'>
        <div class='mt-2 flex flex-col lg:flex-row justify-start items-center lg:items-start'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-48'>대표자명</span>
          <span class='block text-left w-full lg:w-auto'>김명구</span>
        </div>
        <div class='mt-2 flex flex-col lg:flex-row justify-start items-center lg:items-start'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-48'>Tel</span>
          <span class='block text-left w-full lg:w-auto'>02) 780-2766</span>
        </div>
        <div class='mt-2 flex flex-col lg:flex-row justify-start items-center lg:items-start'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-48'>Email</span> 
          <span class='block text-left w-full lg:w-auto'>ortho@koa.or.kr</span>
        </div>
        <div class='mt-2 flex flex-col lg:flex-row justify-start items-center lg:items-start'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-48'>Fax</span> 
          <span class='block text-left w-full lg:w-auto'>02) 780-2767</span>
        </div>
        <div class='mt-2 flex flex-col lg:flex-row justify-start items-center lg:items-start'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-48'>주소</span> 
           <span class='block text-left w-full lg:w-auto'>(04323) 서울특별시 용산구 한강대로 372 (동자동 45) 센트레빌아스테리움서울 A타워 905호</span>
        </div>
        <div class='mt-2 flex flex-col lg:flex-row justify-start items-center lg:items-start'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-48'>사업자등록번호</span> 
          <span class='block text-left w-full lg:w-auto'>106-82-31262</span>
        </div>
        <div class='mt-2 flex flex-col lg:flex-row justify-start text-left w-full items-start'>
          © 2022 Korean Orthopaedic Association
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'Koa1LayoutFooter',
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
    ]),
  },
  methods: {
    ...mapActions('users', [
      'logout',
      'checkTokenStatus',
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.replace({name: 'Login'}).catch(() => {})
      })
    },
    goToHome () {
      this.$router.push({name: 'Welcome'}).catch(() => {})
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken && this.$router.currentRoute.name !== 'Signup') {
        this.$router.replace({name: 'Login'}).catch(() => {})
      }
    })
  }
}
</script>
